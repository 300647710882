import cx from "classnames";
import isNull from "lodash/isNull";
import { memo } from "react";
import { IonImg, IonLabel } from "@ionic/react";

import s from "./styles.module.scss";
import stripeTrusted from "../../../assets/images/wl-security-badge-stripe.png";
import useWebSignupStore, { STEPS } from "../store";
import {
  useStepSubscriptionProvider,
  useUpcomingGiveawaysProvider,
} from "./provider";
import { WLButton } from "../../../../Common/components/WLButton/WLButton";
import { WLSkeleton } from "../../../../Common/components/WLSkeleton/WLSkeleton";
import StripeForm from "./StripePaymentForm/StripeForm";
import PromoCodeSection from "./PromoCodeSection/PromoCodeSection";
import StripeSubscriptionOverview from "./StripeSubscriptionOverview/StripeSubscriptionOverview";
import ChooseMembership from "./ChooseMembership/ChooseMembership";

import CurrentGiveAway from "./CurrentGiveAway/CurrentGiveAway";
import UpcomingGiveaways from "./UpcomingGiveaways/UpcomingGiveaways";
import SignUpMiniGame from "./SignUpMiniGame/SignUpMiniGame";
import { MiniGameResponseDialog } from "../components/MiniGameResponseDialog/MiniGameResponseDialog";
import StepProgressTracker from "../../../../Common/components/WLStepProgressTracker/StepProgressTracker";
// import StepTracker from "../components/StepTracker/StepTracker";

const StepSubscription = () => {
  const {
    selectedSubscription,
    stripeClientSecretId,
    promoEntries,
    lockerWinEntries,
    submitPayment,
    setStep,
  } = useWebSignupStore();
  const {
    promoField,
    validatePromoCode,
    useFormMethods,
    updateSelectedSubscription,
  } = useStepSubscriptionProvider();
  const { currentGiveaway, upcommingGiveaways } =
    useUpcomingGiveawaysProvider();

  const totalEntries =
    (selectedSubscription?.entriesPerMonth || 0) +
    (promoEntries || 0) +
    (lockerWinEntries || 0);

  // console.log(
  //   "should show stripe form? stripeClientSecretId",
  //   stripeClientSecretId
  // );
  return (
    <div className={s.container}>
      <StepProgressTracker
        steps={STEPS}
        currentStep={3}
        onStepChange={(step) => setStep(step)}
      />
      <IonLabel
        className={cx(
          s.stepTitle,
          "wl-h2-winnersans primary normal ion-text-center"
        )}
      >
        READY TO WIN?
      </IonLabel>

      <SignUpMiniGame />

      <div className={s.entriesSummaryContainer}>
        <IonLabel className="wl-h6 primary ion-text-center">
          Your Entry Summary
        </IonLabel>
        {currentGiveaway && (
          <CurrentGiveAway
            currentGiveaway={currentGiveaway}
            entries={totalEntries}
          />
        )}
        <UpcomingGiveaways
          upcomingGiveaways={upcommingGiveaways || []}
          upcomingGiveawayEntries={totalEntries}
        />
        <StripeSubscriptionOverview />
      </div>

      <div className={s.entriesPerMonthLbs}>
        <IonLabel className="wl-body-5 gilroy-bold">
          Entries added to your account every month
        </IonLabel>
        <IonLabel className="wl-body-5 gilroy-bold">
          {selectedSubscription?.entriesPerMonth || 0}
        </IonLabel>
      </div>

      <div className={s.form}>
        <PromoCodeSection
          promoField={promoField}
          validatePromoCode={validatePromoCode}
          useFormMethods={useFormMethods}
        />
        {stripeClientSecretId !== undefined ? (
          !isNull(stripeClientSecretId) ? (
            <StripeForm clientSecret={stripeClientSecretId} />
          ) : (
            <IonLabel className="wl-body-1 danger normal">
              Something went wrong please try again.
            </IonLabel>
          )
        ) : (
          <>
            <WLSkeleton width="100%" height="300px" />
          </>
        )}
        <WLButton
          disabled={
            selectedSubscription === undefined ||
            isNull(selectedSubscription) ||
            stripeClientSecretId === undefined ||
            isNull(stripeClientSecretId)
          }
          onClick={submitPayment}
          className={cx(s.submitBtn, "wl-body-6 red-submit-button")}
          id="wl-sign-up-v3"
          isFormComponent
        >
          ENTER NOW
        </WLButton>
        <IonLabel className={cx(s.cancelNote, "wl-body-6")}>
          No lock-ins, no dramas - cancel anytime!
        </IonLabel>
      </div>
      <IonImg className={s.stripeTrusted} src={stripeTrusted} />

      <ChooseMembership updateSubscription={updateSelectedSubscription} />

      <MiniGameResponseDialog />
    </div>
  );
};

export default memo(StepSubscription);
