import cx from "classnames";
import { memo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import s from "./StripePaymentForm.module.scss";
import { stripeKey } from "../../../firebase";
import StripePaymentForm from "./StripePaymentForm";
import { IonLabel } from "@ionic/react";

const stripePromise = loadStripe(stripeKey);

const StripeForm = ({ amount }: { amount: number }) => {
  return (
    <div className={s.container}>
      <IonLabel className={cx(s.title, "wl-medium primary normal")}>
        PAYMENT
      </IonLabel>

      <Elements
        stripe={stripePromise}
        options={{
          locale: "en-AU",
          amount,
          currency: "aud",
          setup_future_usage: "off_session",
          mode: "subscription",
        }}
      >
        <StripePaymentForm />
      </Elements>
    </div>
  );
};

export default memo(StripeForm);
