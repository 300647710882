import { IonLabel } from "@ionic/react";
import isNull from "lodash/isNull";
import { memo } from "react";
import useWebSignupStore from "../../store";
import s from "./styles.module.scss";
import cx from "classnames";
import { WLButton } from "../../../../../Common/components/WLButton/WLButton";

const StripeSubscriptionOverview = () => {
  const {
    selectedSubscription,
    promoEntries,
    lockerWinEntries,
    setMembershipsModalOpen,
  } = useWebSignupStore();

  const totalEntries =
    (selectedSubscription?.entriesPerMonth || 0) +
    (promoEntries || 0) +
    (lockerWinEntries || 0);

  const onChangePlanClick = () => {
    setMembershipsModalOpen(true);
  };

  return (
    <div className={s.container}>
      {selectedSubscription !== undefined ? (
        !isNull(selectedSubscription) ? (
          <div className={s.entryDetails}>
            <div className={s.row}>
              <div className={s.changeBtnContainer}>
                <IonLabel className="wl-body-2-gilroy dark">
                  {selectedSubscription.name} Membership
                </IonLabel>
                <WLButton
                  fill="clear"
                  className={cx(
                    s.changeBtn,
                    "wl-body-2-gilroy primary ion-no-margin"
                  )}
                  onClick={onChangePlanClick}
                >
                  (Change)
                </WLButton>
              </div>
              <IonLabel className="wl-body-2-gilroy dark">
                ${selectedSubscription.price}/month
              </IonLabel>
            </div>
            <div className={s.row}>
              <IonLabel className="wl-body-2-gilroy dark">
                Entries from joining today
              </IonLabel>
              <IonLabel className="wl-body-2-gilroy dark">
                {selectedSubscription.entriesPerMonth}
              </IonLabel>
            </div>
            <div className={s.row}>
              {/* hasMiniGame */}
              <IonLabel className="wl-body-2-gilroy dark">
                Bonus entries from locker win
              </IonLabel>
              <IonLabel className="wl-body-2-gilroy dark">
                {lockerWinEntries || 0}
              </IonLabel>
            </div>
            <div className={s.row}>
              <IonLabel className="wl-body-2-gilroy dark">
                Promo code bonus
              </IonLabel>
              <IonLabel className="wl-body-2-gilroy dark">
                {promoEntries || 0}
              </IonLabel>
            </div>
            <div className={s.row}>
              <IonLabel className="wl-body-2-gilroy dark gilroy-bold">
                Total entries in this giveaway
              </IonLabel>
              <IonLabel className="wl-body-2-gilroy dark gilroy-bold">
                {totalEntries}
              </IonLabel>
            </div>
          </div>
        ) : (
          <IonLabel className="wl-body-1 danger normal">
            Something went wrong please try again.
          </IonLabel>
        )
      ) : (
        <>{/* Improve loading indicator */}</>
        // <div>
        //   <WLSkeleton width="150px" height="24px" />
        //   <WLSkeleton width="250px" height="32px" />
        //   <WLSkeleton width="100%" height="14px" />
        //   <WLSkeleton width="100%" height="14px" />
        //   <br />
        //   <br />
        //   <WLSkeleton width="100%" height="50px" />
        // </div>
      )}
    </div>
  );
};

export default memo(StripeSubscriptionOverview);
