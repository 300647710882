import { memo } from "react";
import "./StepProgressTracker.scss";
import { IonLabel } from "@ionic/react";

const StepProgressTracker = ({
  steps,
  currentStep,
  onStepChange,
}: {
  steps: string[];
  currentStep: number;
  onStepChange?: (step: number) => void;
}) => {
  return (
    <div className="progress-tracker">
      <div className="steps">
        {steps.map((step, index) => (
          <div key={index} className="step-container">
            <div
              className={`step ${index <= currentStep - 1 ? "completed" : ""} ${
                index === currentStep - 1 ? "active" : ""
              }`}
            >
              <div
                className={`step-lbls ${
                  currentStep <= index + 1 ? "disabled" : ""
                }`}
                onClick={() => {
                  if (onStepChange && currentStep > index + 1) {
                    onStepChange(index + 1);
                  }
                }}
              >
                <IonLabel className="step-number wl-body-2-gilroy">
                  {index + 1}
                </IonLabel>
                <IonLabel className="step-title wl-body-6 ion-text-center">
                  {step}
                </IonLabel>
              </div>
              {index < steps.length - 1 && (
                <div
                  className={`connector ${
                    index < currentStep - 1 ? "completed" : ""
                  }`}
                ></div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(StepProgressTracker);
