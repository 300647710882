import cx from "classnames";
import { memo, useCallback, useEffect, useRef, useState } from "react";

import s from "./SignUpMiniGame.module.scss";
import miniGameInstenseAnimation from "../../../../assets/animations/mini-game-intense-animation.webm";
import miniGameStandardAnimation from "../../../../assets/animations/mini-game-standard-animation.webm";
import miniGameStaticImg from "../../../../assets/images/mini-game-static.webp";
import { IonImg, IonLabel } from "@ionic/react";
import { WLButton } from "../../../../../Common/components/WLButton/WLButton";
import { useMiniGameResponseDialogStore } from "../../components/MiniGameResponseDialog/store";
import useWebSignupStore from "../../store";
import { debounce, isNull } from "lodash";
import {
  calculateTimeRemaining,
  TimeRemaining,
  formatWithLeadingZero,
} from "../../../../../Common/utils/common";
import moment from "moment";
import { useFormsStore } from "../../../../../Common/store/common";
import { onMiniGameTriggered } from "../../../../api/stripe";

const SignUpMiniGame = () => {
  const animationRef = useRef<null | HTMLVideoElement>(null);
  const standardAnimationRef = useRef<null | HTMLVideoElement>(null);
  const { setFormLoading } = useFormsStore(); // Use for clicking unlock and timer start or expired
  const [
    lockerWinEntries,
    lockerWinEntriesExpireDt,
    selectedSubscriptionId,
    setLockerWinEntries,
    setLockerWinEntriesExpireDt,
  ] = useWebSignupStore((state) => [
    state.lockerWinEntries,
    state.lockerWinEntriesExpireDt,
    state.subscriptionIdToPay,
    state.setLockerWinEntries,
    state.setLockerWinEntriesExpireDt,
  ]);
  const [playAnimation, setPlayAnimation] = useState(false);

  const [timerRemaining, setTimeRemaining] = useState<null | TimeRemaining>(
    null
  );
  const [isIntenseAnimation, setIsInstenseAnimation] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleClickUnlock = () => {
    setFormLoading(true);

    setTimeout(() => {
      setPlayAnimation(true);
    }, 150);
  };

  const updateTimer = useCallback(
    debounce((drawDateStamp: number) => {
      if (!isNull(timerRef.current)) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }

      setFormLoading(false);
      setTimeRemaining(calculateTimeRemaining(drawDateStamp));

      timerRef.current = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining(drawDateStamp));
      }, 1000);
    }, 250),
    []
  );

  const handleTimerExpired = () => {
    if (!isNull(timerRef.current)) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }

    setTimeRemaining(null);
    setLockerWinEntries(undefined);
    setLockerWinEntriesExpireDt(undefined);

    setPlayAnimation(false);

    setFormLoading(false);

    if (selectedSubscriptionId) {
      onMiniGameTriggered(selectedSubscriptionId, false);
    }
  };

  useEffect(() => {
    if (lockerWinEntries === undefined) {
      if (!isNull(timerRef.current)) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }

      setTimeRemaining(null);
    } else {
      if (isNull(timerRef.current) && lockerWinEntriesExpireDt) {
        if (moment(lockerWinEntriesExpireDt).isAfter(moment())) {
          updateTimer(lockerWinEntriesExpireDt);
        } else {
          handleTimerExpired();
        }
      }
    }
  }, [lockerWinEntries, lockerWinEntriesExpireDt]);

  useEffect(() => {
    if (
      timerRemaining &&
      timerRemaining.days <= 0 &&
      timerRemaining.hours <= 0 &&
      timerRemaining.mins <= 0 &&
      timerRemaining.secs <= 0
    ) {
      handleTimerExpired();
    }
  }, [timerRemaining]);

  useEffect(() => {
    if (isIntenseAnimation) {
      if (!isNull(animationRef.current) && playAnimation && !lockerWinEntries) {
        animationRef.current.play();
      }
    } else {
      if (
        !isNull(standardAnimationRef.current) &&
        playAnimation &&
        !lockerWinEntries
      ) {
        standardAnimationRef.current.play();
      }
    }
  }, [playAnimation]);

  useEffect(() => {
    if (!playAnimation) {
      const currTime = new Date().getTime();
      setIsInstenseAnimation(currTime % 2 === 0);
    }
  }, [playAnimation]);

  return (
    <div className={s.container}>
      <div
        className={cx(s.innerContainer, {
          [s.hasLockerEntries]: lockerWinEntries,
        })}
      >
        <IonImg
          className={playAnimation ? s.hide : ""}
          src={miniGameStaticImg}
        />
        {/* existing issue when using one video element with src being toggle */}
        <video
          className={!playAnimation ? s.hide : isIntenseAnimation ? "" : s.hide}
          ref={animationRef}
          src={miniGameInstenseAnimation}
          onEnded={() => {
            useMiniGameResponseDialogStore.getState().showDialog();
          }}
        />
        <video
          className={
            !playAnimation ? s.hide : !isIntenseAnimation ? "" : s.hide
          }
          ref={standardAnimationRef}
          src={miniGameStandardAnimation}
          onEnded={() => {
            useMiniGameResponseDialogStore.getState().showDialog();
          }}
        />

        {lockerWinEntries && (
          <div className={s.claimedDetails}>
            <IonLabel className="wl-h5 red ion-text-center">
              HURRY, FINISH SIGNING UP TO LOCK IN YOUR BONUS ENTRIES BEFORE THEY
              EXPIRE!
            </IonLabel>
            {!isNull(timerRemaining) && (
              <div className={s.countdown}>
                <div>
                  <IonLabel className="wl-h6 gilroy-bold red">
                    {formatWithLeadingZero(timerRemaining.mins)}
                  </IonLabel>
                  <IonLabel className="wl-body-6 gilroy-regular red">
                    min
                  </IonLabel>
                </div>
                <IonLabel className="wl-h6 gilroy-bold red">:</IonLabel>
                <div>
                  <IonLabel className="wl-h6 gilroy-bold red">
                    {formatWithLeadingZero(timerRemaining.secs)}
                  </IonLabel>
                  <IonLabel className="wl-body-6 gilroy-regular red">
                    sec
                  </IonLabel>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {!lockerWinEntries && (
        <div className={s.limitedDetails}>
          <IonLabel className="wl-large red normal ion-text-center">
            Limited Time Only!
          </IonLabel>
          <IonLabel
            className={cx(s.limitedDetailsDesc, "wl-body-6 gilroy-regular")}
          >
            Unlock your welcome bonus now for a chance to score extra entries
            and boost your shot at winning the giveaway!
          </IonLabel>

          <WLButton
            disabled={false}
            onClick={handleClickUnlock}
            className={cx(s.spinBtn, "wl-body-6 red-submit-button")}
            isFormComponent
          >
            Spin bonus now
          </WLButton>
        </div>
      )}
    </div>
  );
};

export default memo(SignUpMiniGame);
