import cx from "classnames";
import { useMemo, useRef } from "react";
import { IonLabel, IonModal } from "@ionic/react";

import s from "./MiniGameResponseDialog.module.scss";
import { useMiniGameResponseDialogStore } from "./store";
import { WLButton } from "../../../../../Common/components/WLButton/WLButton";
import Lottie from "lottie-react";
import confetti from "../../../../assets/animations/wl-success-confetti-w-logo.json";
import miniGameAnimation from "../../../../assets/animations/red-entry-ticket-animation.json";
import useWebSignupStore from "../../store";
import { SubscriptionType } from "../../../../constants/subscriptionCardConst";
import { onMiniGameTriggered } from "../../../../api/stripe";
import moment from "moment";
import { trackWebSignUpStep } from "../../../../services/user";

export const MiniGameResponseDialog = () => {
  const modal = useRef<HTMLIonModalElement>(null);
  const [isOpen, hideDialog, reset] = useMiniGameResponseDialogStore(
    (state) => [state.isOpen, state.hideDialog, state.reset]
  );

  const dismiss = () => {
    modal.current?.dismiss();
    hideDialog();
    reset();
  };

  const lockerWinEntries = useMemo(() => {
    let lockerWinEntries = 3;

    return lockerWinEntries;
  }, [isOpen]);

  const hanleClaimEntries = () => {
    const subscriptionId = useWebSignupStore.getState().subscriptionIdToPay;

    if (!subscriptionId) {
      return;
    }

    const setLockerWinEntries =
      useWebSignupStore.getState().setLockerWinEntries;
    setLockerWinEntries(lockerWinEntries);

    const setLockerWinEntriesExpireDt =
      useWebSignupStore.getState().setLockerWinEntriesExpireDt;
    setLockerWinEntriesExpireDt(moment().add(3, "minutes").valueOf());

    // trigger here mini game winner
    onMiniGameTriggered(subscriptionId, true);
    trackWebSignUpStep(
      useWebSignupStore.getState().emailAddress,
      "miniGame",
      4
    );

    dismiss();
  };

  return (
    <IonModal
      id="mini-game-response-dialog"
      className={s.responseDialog}
      backdropDismiss={true}
      ref={modal}
      isOpen={isOpen}
      onDidDismiss={hanleClaimEntries}
    >
      <div className={s.container}>
        <Lottie
          className={s.confettiAnimation}
          animationData={confetti}
          loop={false}
        />
        <Lottie
          className={s.animation}
          animationData={miniGameAnimation}
          loop={true}
        />
        <div className={s.modalContent}>
          <IonLabel className="wl-h7 dark ion-text-uppercase">
            You Beauty
          </IonLabel>
          <IonLabel className="wl-body-4 gilroy blue-grey600 ion-padding-vertical">
            Your chances just got a whole lot better with this epic win:
          </IonLabel>

          {lockerWinEntries && (
            <div className={s.bonusEntriesContainer}>
              <IonLabel className="wl-h8">{lockerWinEntries || 0}</IonLabel>
              <IonLabel className="wl-h5 primary">Bonus Entries</IonLabel>
            </div>
          )}

          <WLButton
            onClick={hanleClaimEntries}
            className={cx(
              s.proceedBtn,
              "red-submit-button wl-body-6 gilroy-medium ion-margin-top"
            )}
          >
            CLAIM NOW
          </WLButton>
        </div>
      </div>
    </IonModal>
  );
};
