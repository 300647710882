import cx from "classnames";
import { memo, useMemo } from "react";

import "swiper/css";
import "swiper/css/pagination";
import s from "./UpcomingGiveaways.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { IonImg, IonLabel } from "@ionic/react";
import { Pagination } from "swiper/modules";
import { Giveaway } from "../../../../../Common/models/giveaways";
import { getDrawnDateByDaysOrMonths } from "../../../../../Common/utils/common";

const UpcomingGiveaways = ({
  upcomingGiveaways,
  upcomingGiveawayEntries,
}: {
  upcomingGiveaways: Giveaway[];
  upcomingGiveawayEntries: number;
}) => {
  const totalGiveaways = useMemo(() => {
    if (!upcomingGiveaways || upcomingGiveaways.length === 0) {
      return 0;
    }

    return upcomingGiveaways
      .reduce(function (a, b) {
        return a + parseFloat(b.value + "");
      }, 0)
      .toLocaleString();
  }, [upcomingGiveaways]);

  return (
    <div className={s.container}>
      <IonLabel className="wl-body-5 winner-sans primary">
        Upcoming Giveaways
      </IonLabel>
      <IonLabel className="wl-body-5 gilroy primary">
        Your membership includes entry to all these giveaways worth $
        {totalGiveaways}!
      </IonLabel>
      <div className={s.swiperContainer}>
        <Swiper
          className={s.swiper}
          slidesPerView={2.36}
          spaceBetween={10}
          initialSlide={0}
          pagination={{ clickable: true }}
          modules={[Pagination]}
        >
          {upcomingGiveaways.map((giveaway, index) => (
            <SwiperSlide key={giveaway.docId} virtualIndex={index}>
              <div className={s.card}>
                <div className={s.innerContainer}>
                  <div className={s.imgContainer}>
                    <IonImg src={giveaway.bannerImgUrl} />
                  </div>
                  <div className={s.giveawayDetails}>
                    <IonLabel className={s.giveawayDetailsLbl}>
                      {giveaway.title}
                    </IonLabel>
                    <IonLabel className={s.drawnLbl}>
                      Drawn: {getDrawnDateByDaysOrMonths(giveaway.giveAwayDt)}
                    </IonLabel>
                    <IonLabel className={s.entriesLbl}>
                      Your Entries: {upcomingGiveawayEntries}
                    </IonLabel>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default memo(UpcomingGiveaways);
