import capitalize from "lodash/capitalize";
import clone from "lodash/clone";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import replace from "lodash/replace";
import startCase from "lodash/startCase";
import find from "lodash/find";
import filter from "lodash/filter";

import moment from "moment";
import { isPlatform } from "@ionic/react";
import { useEffect } from "react";
import { Keyboard } from "@capacitor/keyboard";

import * as capacitorStorage from "./localStorage";
import { LOCAL_STORAGE } from "../config";
import { WLSubscriptionsTiers } from "../models/subscriptions";
import { parsePhoneNumber } from "libphonenumber-js";
import { Giveaway } from "../models/giveaways";

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useEffectOnlyOnce = (func: () => void) => useEffect(func, []);

export const isMobile = (allowMobileWeb = false) =>
  (isPlatform("ios") || isPlatform("android")) &&
  (!allowMobileWeb
    ? !isPlatform("mobileweb") &&
      (!isPlatform("desktop") ||
        (isPlatform("desktop") && isPlatform("hybrid")))
    : true);

export const isAndroid = () =>
  isPlatform("android") && !isPlatform("mobileweb");

export const getDaysDifference = (date: Date) => {
  const currentDate = new Date();
  const diff = moment(currentDate).diff(date, "minutes");
  const dayMins = 24 * 60; //
  const counter = (diff - (diff % dayMins)) / dayMins;
  return counter;
};

export const keyboardListener = (callback: (didShow: boolean) => void) => {
  if (isMobile()) {
    Keyboard.addListener("keyboardWillShow", () => {
      callback(true);
    });

    Keyboard.addListener("keyboardDidHide", () => {
      callback(false);
    });

    return () => {
      Keyboard.removeAllListeners();
    };
  } else {
    callback(false);
    return () => {};
  }
};

export const numberWithCommas = (x: number) => {
  const result = (typeof x === "string" ? parseInt(x) : x || 0)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const resultSplit = result.split(".");
  const remainder = result.split(".")[1];

  if (remainder === "00") {
    return resultSplit[0];
  } else {
    return result;
  }
};

export const abbreviateNumber = (n: number) => {
  if (n < 1e3) return n + "";
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(3) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(3) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(3) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(3) + "T";

  return "";
};

export const isHistoricalDate = (date: Date) => {
  return moment(moment(date).format("YYYY-MM-DD")).isBefore(
    moment(new Date()).format("YYYY-MM-DD")
  );
};

export const isSameDate = (date: Date, date2: Date) => {
  return moment(date).isSame(moment(date2), "day");
};

export const encodeQueryData = (data: any) => {
  return Object.keys(data)
    .map(function (key: any) {
      return [key, data[key]].map(encodeURIComponent).join("=");
    })
    .join("&");
};

export const replaceAll = (
  data: string,
  replaceLetter: string,
  replaceWith: string
) => {
  return data
    .split("")
    .map((letter) => {
      if (letter === replaceLetter) {
        return replaceWith;
      } else {
        return letter;
      }
    })
    .join("");
};

export const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = (reader.result as string).split(",")[1];
      resolve(base64String);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  }) as Promise<string>;
};

export const executePromisesInSeries = async (
  promiseFns: (() => Promise<any>)[]
): Promise<any[]> => {
  const results: any[] = [];

  for (const promiseFn of promiseFns) {
    const result = await promiseFn();
    results.push(result);
  }

  return results;
};

export const checkIfCanVerifyMobile = async (
  callBack: (remainingTime: number) => void
) => {
  const recentMobileVerification = parseInt(
    (await capacitorStorage.getItem(LOCAL_STORAGE.recentMobileVerification)) ||
      "0"
  );

  const currentTime = new Date().valueOf();
  const timeLimit = 30000;
  const timePassed = currentTime - recentMobileVerification;
  const remainingTime =
    (timeLimit - timePassed) / 1000 > 1 ? (timeLimit - timePassed) / 1000 : 0;

  let coolDown = clone(remainingTime);
  let intervalId: NodeJS.Timeout | null = null;

  if (!!remainingTime) {
    // this.setState({ mobileVerificationCooldown: remainingTime });
    callBack(remainingTime);

    intervalId = setInterval(() => {
      const nextRemainingTime = coolDown - 1;
      if (nextRemainingTime <= 0) {
        coolDown = 0;
        callBack(0);
        clearInterval(intervalId!);
      } else {
        callBack(nextRemainingTime); // ~~ is roundoff
        coolDown = nextRemainingTime;
      }
    }, 1000);
  }

  return intervalId;
};

export const setRecentMobileVerification = () => {
  capacitorStorage.setItem(
    LOCAL_STORAGE.recentMobileVerification,
    new Date().valueOf().toString()
  );
};

export const calculateDays = (d: number) => {
  let months = 0,
    years = 0,
    days = 0,
    weeks = 0;
  if (d > 0) {
    while (d) {
      if (d >= 365) {
        years++;
        d -= 365;
      } else if (d >= 30) {
        months++;
        d -= 30;
      } else if (d >= 7) {
        weeks++;
        d -= 7;
      } else {
        days++;
        d--;
      }
    }
  }
  return {
    years,
    months,
    weeks,
    days,
  };
};

export const toIsoDateTime = (dateTimeString: Date): string => {
  const year = dateTimeString.getFullYear();
  const month = (dateTimeString.getMonth() + 1).toString().padStart(2, "0");
  const dateOfMonth = dateTimeString.getDate().toString().padStart(2, "0");
  const hour = dateTimeString.getHours().toString().padStart(2, "0");
  const minute = dateTimeString.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${dateOfMonth}T${hour}:${minute}:00.000`;
};

export const isUserStillSubscribed = ({
  dateSubscribed,
  subscription,
}: {
  dateSubscribed: number;
  subscription?: WLSubscriptionsTiers;
}) => {
  return dateSubscribed && !isEmpty(subscription);
};

export const shuffle = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    // Generate a random index
    const j = Math.floor(Math.random() * (i + 1));

    // Swap elements at i and j
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const getErrorMsg = (error: any) => {
  let result = (
    (error.response?.data?.errors !== undefined &&
      error.response?.data?.errors[0]?.detail) ||
    error.response?.data ||
    error.message ||
    error
  )
    .trim()
    .toLowerCase();

  if (result.indexOf("auth/user-not-found") !== -1) {
    result = "User not found.";
  } else if (result.indexOf("auth/wrong-password") !== -1) {
    result = "Wrong email or password, Please try again.";
  } else if (result.indexOf("auth/network-request-failed") !== -1) {
    result = "Something went wrong. Please try again.";
  } else if (result.indexOf("auth/email-already-in-use") !== -1) {
    result = "An account with this email address already exists.";
  } else if (result.indexOf("auth/requires-recent-login") !== -1) {
    result = "This operation requires logging back in.";
  } else if (result.indexOf("auth/too-many-requests") !== -1) {
    result =
      "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.";
  } else if (result.indexOf("auth/invalid-email") !== -1) {
    result = "Incorrect email address format.";
  } else if (result.indexOf("Indexed Database") !== -1) {
    result = "Connection lost. Please try again.";
  } else if (result.indexOf("500") !== -1) {
    result = "Something went wrong. Please try again.";
  } else if (result.indexOf("Request failed with status code 400") !== -1) {
    result = "Please try again";
  } else if (result.indexOf("not found") !== -1 && result.indexOf("/") !== -1) {
    result =
      "Something went wrong. Please check your internet connection and try again.";
  } else if (result.indexOf("indexed database") !== -1) {
    result =
      "Oops! We're having trouble loading. Check your internet and give it another go";
  }

  return capitalize(result);
};

type SupportedCountries = "AU" | "PH" | "NZ";
export const validateNumber = (phoneNumber: string) => {
  const allowedCountries: { [countryCode: string]: SupportedCountries } = {
    "+61": "AU",
    "+63": "PH",
    "+64": "NZ",
  };

  const phoneCountryCode = phoneNumber.slice(0, 3);
  if (!(phoneCountryCode in allowedCountries)) {
    return "Unsupported country";
  }

  const trailingFormattedNumber = replaceAll(
    `${replace(phoneNumber.slice(3), /^0/, "")}`,
    " ",
    ""
  );
  try {
    const formattedNumber = parsePhoneNumber(
      `${phoneCountryCode}${trailingFormattedNumber}`,
      allowedCountries[phoneCountryCode]
    );
    // console.log({
    //   phoneCountryCode,
    //   trailingFormattedNumber,
    //   code: allowedCountries[phoneCountryCode],
    //   toCheck: `${phoneCountryCode}${trailingFormattedNumber}`,
    //   checked: formattedNumber.number,
    // });

    if (!formattedNumber.isValid()) {
      return "Invalid phone number";
    }
  } catch (error) {
    return "Invalid phone number";
  }

  return true;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const phoneCountryCode = phoneNumber.slice(0, 3);

  const trailingFormattedNumber = replaceAll(
    `${replace(phoneNumber.slice(3), /^0/, "")}`,
    " ",
    ""
  );

  return `${phoneCountryCode}${trailingFormattedNumber}`;
};

export const UPPERCASE_FIELDS = ["firstName", "lastName"];
export const formatForm = (formData: any) => {
  const result = {} as { [key: string]: any };
  Object.keys(formData).forEach((key) => {
    const data = formData[key];
    result[key] = includes(UPPERCASE_FIELDS, key)
      ? startCase(data.toLowerCase())
      : data;
  });

  return result;
};

export const downloadJSONToCSV = (
  jsonList: Array<any>,
  filename: string = "export.csv"
): void => {
  // Generate the header row from the object keys
  const keys = Object.keys(jsonList[0]);
  let csvContent = keys.join(",") + "\n";

  // Generate the content rows
  jsonList.forEach((row) => {
    const values = keys.map((key) => {
      // Quote strings that contain commas or newlines
      const value = row[key];
      return typeof value === "string" && /[,\n]/.test(value)
        ? `"${value}"`
        : value;
    });
    csvContent += values.join(",") + "\n";
  });

  // Create a Blob with the CSV content
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Create a download link
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;

  // Append and trigger download, then remove link
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const copyToClipboard = async (text: string) => {
  await navigator.clipboard.writeText(text);
};

export const getCountdownDisplay = (giveaway: Giveaway) => {
  const yetToOpen =
    giveaway.openDt !== undefined && giveaway.openDt > new Date().valueOf();
  const date = yetToOpen ? giveaway.openDt! : giveaway.giveAwayDt;

  const currentDate = new Date().getTime();
  const diff = date - currentDate;
  const day = 1000 * 60 * 60 * 24;
  const daysLeft = Math.round(diff / day);
  const countdown = calculateDays(daysLeft);

  const { years, months, weeks, days } = countdown;

  const yearLabel = years > 1 ? "YEARS" : "YEAR";
  const monthLabel = months > 1 ? "MONTHS" : "MONTH";
  const weekLabel = weeks > 1 ? "WEEKS" : "WEEK";
  const dayLabel = days > 1 ? "DAYS" : "DAY";

  const drawLabel = yetToOpen ? "DRAW OPENS IN" : "DRAW CLOSES IN";
  if (years) return `${drawLabel} ${years} ${yearLabel}`;
  else if (months) return `${drawLabel} ${months} ${monthLabel}`;
  else if (weeks) return `${drawLabel} ${weeks} ${weekLabel}`;
  else
    return days > 0 ? `${drawLabel} ${days} ${dayLabel}` : "DRAW OPENS TODAY";
};

export const getDrawnDateByDaysOrMonths = (drawnDate: number) => {
  const now = moment();
  const diff = moment.duration(moment(drawnDate).diff(now));
  const diffMonths = diff.months();
  if (diffMonths < 1) {
    const diffDays = diff.days();
    if (diffDays < 2) {
      return "1 Day";
    } else {
      return `${diffDays} Days`;
    }
  } else if (diffMonths === 1) {
    return "1 Month";
  } else {
    return `${diff.months()} Months`;
  }
};

export const formatPriceString = (num: number): string => {
  return num % 1 !== 0 ? num.toFixed(2) : num.toString();
};

export const hashStringToSHA256 = async (str: string) => {
  // Convert the string to an ArrayBuffer
  const encoder = new TextEncoder();
  const data = encoder.encode(str);

  // Hash the data with SHA-256
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);

  // Convert the buffer to a hexadecimal string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

  return hashHex;
};

export interface TimeRemaining {
  days: number;
  hours: number;
  mins: number;
  secs: number;
}

export const calculateTimeRemaining = (endDate: number): TimeRemaining => {
  const now = new Date().valueOf();
  const distance = endDate - now;

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const mins = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const secs = Math.floor((distance % (1000 * 60)) / 1000);

  return { days, hours, mins, secs };
};

export const formatWithLeadingZero = (num: number) => {
  return num.toString().padStart(2, "0");
};

// <T, K extends keyof T> This is to declare types that we can cast to the parameters, to make this function generic and typesafe
export const betterStringSearch = <T, K extends keyof T>(
  dataList: T[],
  searchQuery: string,
  fieldsToCheck: K[]
): T[] => {
  const queries = searchQuery.trim().toLowerCase().split(" ");

  return dataList.filter((item) => {
    const textsToCheck = fieldsToCheck
      .map((field) => item[field]?.toString().trim().toLowerCase())
      .join(" ")
      .split(" ");

    const matchedResult = find(
      textsToCheck,
      (text) => !isEmpty(filter(queries, (query) => text.indexOf(query) !== -1))
    );

    return !!matchedResult;
  });
};

export const isInAppBrowser = () => {
  try {
    const userAgent = window.navigator.userAgent || navigator.vendor;

    // Detect Android WebView (typically contains "wv" or "WebView")
    const isAndroidWebView = /Android/.test(userAgent) && /wv/.test(userAgent);

    // Detect iOS WebView (iOS devices running without Safari, or using WKWebView)
    const isIOSWebView =
      /iPhone|iPod|iPad/i.test(userAgent) && !/Safari/i.test(userAgent);

    // Return true if it's either an Android or iOS WebView
    return isAndroidWebView || isIOSWebView;
  } catch (error) {
    console.error("Error detecting in-app browser or WebView:", error);
    return true; // Default to true if error
  }
};
