import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router";
import { IonRouterOutlet } from "@ionic/react";

import "./Router.scss";
import * as routes from "../constants/routes";
import { ForgotPassword } from "../pages/ForgotPassword/ForgotPassword";
import { WebSignUp } from "../pages/WebSignUpWGiveawayCarousel/WebSignUp";
import { WebSignUp as SinglePageWebSignUp } from "../pages/SinglePageWebSignUp/WebSignUp";
import { WebPaymentLanding } from "../pages/WebPaymentLanding/WebPaymentLanding";
import { ResetPassword } from "../pages/ResetPassword/ResetPassword";

export const RouterComponent = () => {
  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path={routes.WEB_SIGN_UP_V4} component={WebSignUp} />
        <Route
          exact
          path={routes.WEB_SIGN_UP_V6}
          component={SinglePageWebSignUp}
        />
        <Route
          path={routes.WEB_DOWNLOAD_APP}
          component={WebPaymentLanding}
          exact
        />
        <Route exact path={routes.RESET_PASSWORD} component={ResetPassword} />
        <Route exact path={routes.FORGOT_PASSWORD} component={ForgotPassword} />
        <Redirect exact from="/" to={`/join-v4/mvp`} />

        <Route component={() => <Redirect to={`/join-v4/mvp`} />} />
      </IonRouterOutlet>
    </IonReactRouter>
  );
};
