import { create } from "zustand";

interface iMiniGameResponseDialogStore {
  isOpen: boolean;
  showDialog: () => void;
  hideDialog: () => void;
  reset: () => void;
}

export const useMiniGameResponseDialogStore =
  create<iMiniGameResponseDialogStore>((set) => ({
    isOpen: false,
    showDialog: () =>
      set({
        isOpen: true,
      }),
    hideDialog: () =>
      set({
        isOpen: false,
      }),
    reset: () =>
      set({
        isOpen: false,
      }),
  }));
