import cx from "classnames";
import { memo, useMemo, useState } from "react";

import { WLButton } from "../../../../../Common/components/WLButton/WLButton";
import {
  WLFormInput,
  WLFormInputProps,
} from "../../../../../Common/components/WLForms/WLFormInput/WLFormInput";
import useWebSignupStore from "../../store";
import s from "./styles.module.scss";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";
import { IonIcon, IonLabel } from "@ionic/react";
import {
  getStripePromoAmount,
  getWLPromoEntries,
} from "../../../../services/promo";
import {
  alertCircleOutline,
  checkmarkCircle,
  chevronDownOutline,
  chevronUpOutline,
} from "ionicons/icons";

interface iPromoCodeSection {
  promoField: WLFormInputProps;
  validatePromoCode: (
    overrideCode?: string
  ) => Promise<true | "Invalid promo code">;
  useFormMethods: UseFormReturn<FieldValues, any, undefined>;
}
const PromoCodeSection = ({
  promoField,
  validatePromoCode,
  useFormMethods,
}: iPromoCodeSection) => {
  const {
    selectedSubscription,
    validStripeCoupon,
    validPromoCodePromo,
    validPromoCode,
  } = useWebSignupStore();

  const [showPromoField, setShowPromoField] = useState(false);

  const promoDetails = useMemo(() => {
    const promoCode = useFormMethods.getValues("promoCode");
    if (
      !selectedSubscription ||
      promoCode === undefined ||
      promoCode === "" ||
      validPromoCode === undefined
    ) {
      return {
        title: "",
        description: "",
        error: false,
      };
    }

    if (validStripeCoupon && validPromoCodePromo) {
      return {
        title: "Promo Applied!",
        description: `Absolute beauty, $${getStripePromoAmount(
          validStripeCoupon,
          selectedSubscription.price
        )} off your payment is locked in and ${getWLPromoEntries(
          validPromoCodePromo,
          selectedSubscription
        )} bonus entries will be applied when you join!`,
        error: false,
      };
    } else if (validStripeCoupon) {
      return {
        title: "Promo Applied!",
        description: `Nice one, $${getStripePromoAmount(
          validStripeCoupon,
          selectedSubscription.price
        )} off your payment has been locked in!`,
        error: false,
      };
    } else if (validPromoCodePromo) {
      return {
        title: "Promo Applied!",
        description: `Nice one, ${getWLPromoEntries(
          validPromoCodePromo,
          selectedSubscription
        )} bonus entries will be applied when you join!`,
        error: false,
      };
    } else {
      return {
        title: "Uh-oh!",
        description: "That promo code didn’t work, try again.",
        error: true,
      };
    }
  }, [validStripeCoupon, validPromoCodePromo, selectedSubscription]);

  return (
    <FormProvider {...useFormMethods}>
      {selectedSubscription !== undefined && (
        <form
          className={s.container}
          onSubmit={(event) => event.preventDefault()}
        >
          <div className={s.havePromoCode}>
            <IonLabel className="wl-h5 gilroy-regular">
              Have a Promo Code?
            </IonLabel>
            <IonIcon
              icon={showPromoField ? chevronDownOutline : chevronUpOutline}
              onClick={() => setShowPromoField(!showPromoField)}
            />
          </div>
          {showPromoField && (
            <div className={s.promoCodeInputContainer}>
              <WLFormInput className={cx(s.promoCodeInput)} {...promoField} />
              <WLButton
                onClick={() => {
                  validatePromoCode();
                }}
                className={cx(
                  s.applyBtn,
                  "wl-body-6 red-submit-button ion-no-margin"
                )}
                isFormComponent
              >
                Apply
              </WLButton>
            </div>
          )}
        </form>
      )}

      {!!promoDetails.title && !!promoDetails.description && (
        <div
          className={cx(s.promoDescriptionContainer, {
            [s.error]: promoDetails.error,
          })}
        >
          <div className={s.promoDetailsTitle}>
            <IonIcon
              icon={promoDetails.error ? alertCircleOutline : checkmarkCircle}
              color={promoDetails.error ? "danger" : "success"}
            />
            <IonLabel
              className={cx(
                "wl-body-6 gilroy-bold",
                promoDetails.error ? "danger" : "success"
              )}
            >
              {promoDetails.title}
            </IonLabel>
          </div>
          <IonLabel className="wl-body-6 gilroy-regular ion-text-start">
            {promoDetails.description}
          </IonLabel>
        </div>
      )}
    </FormProvider>
  );
};

export default memo(PromoCodeSection);
