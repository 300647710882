import cx from "classnames";
import { memo } from "react";

import s from "./CurrentGiveAway.module.scss";
import { Giveaway } from "../../../../../Common/models/giveaways";
import { IonImg, IonLabel } from "@ionic/react";
import { getDrawnDateByDaysOrMonths } from "../../../../../Common/utils/common";

interface iCurrentGiveaway {
  currentGiveaway: Giveaway;
  entries: number;
}
const CurrentGiveaway = ({ currentGiveaway, entries }: iCurrentGiveaway) => {
  return (
    <div>
      <IonLabel className="wl-h4 primary">Current Giveaway</IonLabel>
      <div className={s.bannerGiveaway}>
        <div className={s.imgContainer}>
          <IonImg src={currentGiveaway.bannerImgUrl} />
        </div>
        <div className={s.giveawayDetails}>
          <IonLabel className={cx(s.giveawayTitle, "wl-h4 gilroy-bold white")}>
            {currentGiveaway.title}
          </IonLabel>
          <IonLabel className="wl-body-1-gilroy regular white">
            Drawn: {getDrawnDateByDaysOrMonths(currentGiveaway.giveAwayDt)}
          </IonLabel>
          <IonLabel className="wl-body-1-gilroy gilroy-bold white">
            Your Entries: {entries}
          </IonLabel>
        </div>
      </div>
    </div>
  );
};

export default memo(CurrentGiveaway);
